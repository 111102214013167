var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Jumbotron",
        { attrs: { title: _vm.$t("pages.host.HostModulesIndex.title") } },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("pages.host.HostModulesIndex.subTitle")) + " "
          )
        ]
      ),
      _vm.ready
        ? _c(
            "CRow",
            [
              _c(
                "CCol",
                { attrs: { md: "8" } },
                [
                  _c("DataTable", {
                    attrs: {
                      items: _vm.data.modules.items,
                      fields: [
                        {
                          key: "name",
                          label: "Name",
                          sorter: false,
                          filter: false
                        },
                        {
                          key: "status",
                          label: "Status",
                          sorter: false,
                          filter: false
                        },
                        {
                          key: "isActive",
                          label: "Aktiv",
                          sorter: false,
                          filter: false
                        },
                        {
                          key: "endedAt",
                          label: "Gebucht bis",
                          sorter: false,
                          filter: false
                        }
                      ],
                      hover: "",
                      loading: _vm.loading
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "name",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                [
                                  item.isActive && _vm.moduleLinkName(item.name)
                                    ? _c(
                                        "routerLink",
                                        {
                                          attrs: {
                                            to: {
                                              name: _vm.moduleLinkName(
                                                item.name
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "enums.module." + item.name
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !item.isActive ||
                                  !_vm.moduleLinkName(item.name)
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "enums.module." + item.name
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "isActive",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "pages.host.HostModulesIndex.active." +
                                          item.isActive
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "status",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "enums.moduleStatus." + item.status
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "endedAt",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                [
                                  item.isActive && item.endedAt != ""
                                    ? _c("FormatedTimestamp", {
                                        attrs: { date: item.endedAt }
                                      })
                                    : _vm._e(),
                                  item.isActive && item.endedAt == ""
                                    ? _c("span", {
                                        directives: [
                                          {
                                            name: "t",
                                            rawName: "v-t",
                                            value:
                                              "pages.host.HostModulesIndex.endedAtNotSet",
                                            expression:
                                              "'pages.host.HostModulesIndex.endedAtNotSet'"
                                          }
                                        ]
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3521756103
                    )
                  }),
                  _vm.pages > 1
                    ? _c("CPagination", {
                        attrs: {
                          pages: _vm.pages,
                          activePage: _vm.currentPage
                        },
                        on: {
                          "update:activePage": function($event) {
                            _vm.currentPage = $event
                          },
                          "update:active-page": function($event) {
                            _vm.currentPage = $event
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { md: "4" } },
                [
                  _vm.ready
                    ? _c(
                        "CCard",
                        [
                          _c("CCardHeader", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value: "pages.host.HostModulesIndex.helpTitle",
                                expression:
                                  "'pages.host.HostModulesIndex.helpTitle'"
                              }
                            ]
                          }),
                          _c("CCardBody", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value: "pages.host.HostModulesIndex.helpText",
                                expression:
                                  "'pages.host.HostModulesIndex.helpText'"
                              }
                            ]
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }